import React, { useEffect, useState } from 'react';
import { CompatRoute, useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { IsLoggedIn } from './services/AuthService';
import { fetchValidUrl, prepareUrl } from './services/LoanService';

import ErrorComponent from './components/ErrorComponent';
import LoadingSpinner from './components/SpinnerOverlay';

const errors = ['HttpError', 'ConnectionError'];
const nonActiveLoanUrl = '/pos/mensaje-simple?msg_id=no_active_loan';

export function correctUrl(urls, { location: { pathname } }) {
  return urls.includes(`/app${pathname}`);
}

/**
  * temporal param type to silence eslint
  * @param {object} props
 */
export function ApurataRoute({
  component: Component, auth, checkUrl,
  redirectOnNonActiveLoan, setIntervalTime, ...rest
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkUrlRedirect = () => fetchValidUrl().then(
      (urls) => {
        if (!correctUrl(urls, rest)) {
          navigate(prepareUrl(urls[0]));
        }
      },
      (err) => {
        if (err.name === 'HttpError') {
          if (err.status === 404) {
            if (redirectOnNonActiveLoan) {
              navigate(prepareUrl(nonActiveLoanUrl));
            }
          }
        } else if (err.name === 'ConnectionError') {
          setError(err);
        } else {
          throw err;
        }
      },
    );
    const checkLoginStatus = async () => {
      const loggedIn = await IsLoggedIn();
      if (!loggedIn) {
        if (auth) {
          window.location = `/login?next=/app${location.pathname}`;
        }
      }
    };
    checkLoginStatus();

    if (checkUrl) {
      checkUrlRedirect().then(() => setLoading(false));
      if (setIntervalTime) {
        const interval = setInterval(checkUrlRedirect, setIntervalTime);
        return () => clearInterval(interval);
      }
    } else {
      setLoading(false);
    }
  }, [checkUrl, setIntervalTime, navigate, location.pathname, redirectOnNonActiveLoan]);
  // be specific to avoid reloading

  const checkUrlFunnelStage = () => fetchValidUrl().then(
    (urls) => {
      if (!correctUrl(urls, rest)) {
        navigate(prepareUrl(urls[0]));
      }
    },
    (err) => {
      if (errors.includes(err)) {
        setError(err);
      } else {
        throw err;
      }
    },
  );

  return (
    <CompatRoute
      /* eslint-disable react/jsx-props-no-spreading */
      {...rest}
      render={
        (props) => {
          if (loading) {
            return (
              <LoadingSpinner show text="Cargando información" />
            );
          }
          if (error) {
            return <ErrorComponent error={error} />;
          }
          /* eslint-disable react/jsx-props-no-spreading */
          return <Component checkUrlFunnelStage={checkUrlFunnelStage} {...props} />;
        }
      }
    />
  );
}
