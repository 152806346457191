import React from 'react';

export interface TCognitoContext {
  email: string;
  showEmail: string;
  setEmail: (email: string) => void;
}

const CognitoDefaultContext: TCognitoContext = {
  email: '',
  showEmail: '',
  setEmail: () => {},
};

export const CognitoContext = React.createContext(CognitoDefaultContext);

export function CognitoContextProvider({ children }: { children: React.ReactNode }) {
  const [email, setEmail] = React.useState('');
  const showEmail = email.replace(/(.{3}).*(?=@)/, '$1***').replace(/(@.{3}).*/, '$1***'); // asd***@asd***
  const memoizedContextValue = React.useMemo(
    () => ({
      email,
      showEmail,
      setEmail,
    }),
    [email],
  );

  return <CognitoContext.Provider value={memoizedContextValue}>{children}</CognitoContext.Provider>;
}
