import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  BrowserRouter, Switch, Redirect, Route,
} from 'react-router-dom';
import {
  CompatRouter,
  useParams,
} from 'react-router-dom-v5-compat';
import * as Sentry from '@sentry/react';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import LoadingSpinner from './components/SpinnerOverlay';
import RenderLoader from './components/RenderLoader';
import ErrorFallback from './components/BoundaryError';
import ErrorComponent from './components/ErrorComponent';
import AppLayout from './components/AppLayout';
import GlobalContextProviders from './context/GlobalContextProviders';
// Bootstrap is used across the whole project, thus we load it before anything else
import 'bootstrap/dist/css/bootstrap.min.css'; // +145kb
// Toastify is used across the whole project, thus we load it before anything else
import 'react-toastify/dist/ReactToastify.css'; // +10kb
import { SaveSessionIdLocalStorage, SaveUtmsInSession } from './services/SessionCookie';
import getOrCreateGrowthbookClient from './services/GbClient';
import { ApurataRoute } from './routes';
import CognitoLayout from './screens/CognitoLogin/CognitoLayout';

/* eslint-disable import/no-cycle */
const ApplicationStatus = lazy(() => import('./screens/Funnel/ApplicationStatus/ApplicationStatus'));
const ApplyDeniedOtherAccount = lazy(() => import('./screens/Funnel/Conflicts/ApplyDeniedOtherAccount'));
const ApplyReapplyWait = lazy(() => import('./screens/Funnel/Conflicts/ApplyReapplyWait'));
const CameraValidation = lazy(() => import('./screens/CameraValidation'));
const CognitoLogin = lazy(() => import('./screens/CognitoLogin/CognitoLogin'));
const CognitoResetPassword = lazy(() => import('./screens/CognitoLogin/CognitoResetPassword'));
const CognitoVerifyEmail = lazy(() => import('./screens/CognitoLogin/CognitoVerifyEmail'));
const DistinctDni = lazy(() => import('./screens/Conflict/DistinctDni/DistinctDniScreen'));
const Funded = lazy(() => import('./screens/Funnel/FundedApurata/Funded'));
const Login = lazy(() => import('./screens/Login/LoginScreen'));
const LowerApprovedAmount = lazy(() => import('./screens/Funnel/ApplicationStatus/LowerApprovedAmount'));
const MoreData = lazy(() => import('./screens/Funnel/MoreData'));
const RetryValidation = lazy(() => import('./screens/Funnel/RetryValidation/RetryValidation'));
const ScreenDownPayment = lazy(() => import('./screens/Downpayment/ScreenDownPayment'));
const ScreenTestPrd = lazy(() => import('./screens/Downpayment/ScreenTestPrd'));
const ValidatingApurata = lazy(() => import('./screens/Funnel/ValidatingApurata/ValidatingApurata'));
const WhyDeclined = lazy(() => import('./screens/Funnel/WhyDeclined/WhyDeclined'));
// const Home = lazy(() => import('./screens/Home'));
const HomePeople = lazy(() => import('./screens/HomePeople/HomePeople'));
const Integrations = lazy(() => import('./screens/Integrations'));
const LandingMerchant = lazy(() => import('./screens/LandingMerchant'));
const LandingStores = lazy(() => import('./screens/LandingStores/LandingMarketplace'));
const LandingPartner = lazy(() => import('./screens/LandingPartner/LandingPartner'));
const MercadoPago = lazy(() => import('./screens/Versus/MercadoPago'));
const NotFound = lazy(() => import('./screens/NotFound'));
const PhoneEmailIdVerification = lazy(() => import('./screens/VerificationsPhoneEmailID/PhoneEmailIdVerification'));
const RedirectCountdown = lazy(() => import('./screens/RedirectCountDown/RedirectCountdown'));
const ValidatingPhotoAndPayment = lazy(() => import('./screens/ValidatingPhotoAndPayment'));
const ValidatingTransaction = lazy(() => import('./screens/ValidatingTransaction'));
const FundingCodePos = lazy(() => import('./screens/FundingCodePos'));
const SuccessPurchase = lazy(() => import('./screens/SuccessPurchase'));
const Plugin = lazy(() => import('./screens/Plugin'));
const PaymentInstructions = lazy(() => import('./screens/Paylink/PaymentInstructions'));
const RegisterDownpaymentVoucher = lazy(() => import('./screens/Paylink/RegisterPayment'));
const Landing = lazy(() => import('./screens/Paylink/Landing'));
const Dashboard = lazy(() => import('./screens/Paylink/Dashboard'));
const DashboardList = lazy(() => import('./screens/Paylink/DashboardList'));
const ProfileForm = lazy(() => import('./screens/Paylink/ProfileForm/ProfileForm'));
const RegisterVoucher = lazy(() => import('./screens/Funnel/RegisterPayment/RegisterVoucher'));
const PaymentFunnel = lazy(() => import('./screens/Funnel/RegisterPayment/RegisterPayment'));
const StepOne = lazy(() => import('./screens/StepOne/StepOne'));
const DontCreateLoans = lazy(() => import('./screens/FunnelErrors/DontCreateLoans'));
const DontFinanceLoan = lazy(() => import('./screens/FunnelErrors/DontFinanceLoan'));
const FailCancelLend = lazy(() => import('./screens/FunnelErrors/FailCancelLend'));
const NudgeAcuotaz = lazy(() => import('./screens/NudgeAcuotaz/NudgeAcuotaz'));
const LayoutPaylink = lazy(() => import('./screens/Paylink/LayoutPaylink'));
const DownpaymentCard = lazy(() => import('./screens/DownpaymentCard'));
const DownpaymentTransfer = lazy(() => import('./screens/DownpaymentTransfer/index'));
const DownpaymentYape = lazy(() => import('./screens/DownpaymentYape/DownPaymentYape'));
const DownpaymentFullLoan = lazy(() => import('./screens/DownpaymentFullLoan/DownpaymentFullLoan'));
const ResultsValidationDownpayment = lazy(() => import('./screens/DownpaymentFullLoan/ResultsValidationDownpayment'));
const PhotocheckScreen = lazy(() => import('./screens/Photocheck/Photocheck'));
const ScreenSimulatorAcuotaz = lazy(() => import('./screens/Simulators/ScreenSimulatorAcuotaz'));
const ScreenSimulatorPrecal = lazy(() => import('./screens/Simulators/ScreenSimulatorPrecal'));
const PrecalEval = lazy(() => import('./screens/Funnel/precal/PrecalEval'));
const PrecalApproved = lazy(() => import('./screens/Funnel/precal/PrecalApproved'));
const PrecalRejected = lazy(() => import('./screens/Funnel/precal/PrecalRejected'));
const PrecalIncompleted = lazy(() => import('./screens/Funnel/precal/PrecalIncompleted'));
const ExternalDownPaymentInstructions = lazy(() => import('./screens/ExternalDownPaymentInstructions'));
const ScreenExtendedLine = lazy(() => import('./screens/Funnel/ScreenExtendedLine'));
const VouchersTableTest = lazy(() => import('./screens/Funnel/VoucherTableTest'));
const ApplyDenied = lazy(() => import('./screens/Funnel/ApplyDenied/ApplyDenied'));
const Evaluating = lazy(() => import('./screens/Funnel/Evaluating/Evaluating'));
const CancelExternalProblems = lazy(() => import('./screens/Funnel/ExternalProblems/ExternalProblems'));
const MoreDataForms = lazy(() => import('./screens/Funnel/MoreInfoForms/MoreDataForms'));
const MyProfile = lazy(() => import('./screens/MyProfile/MyProfile'));

/* eslint-enable import/no-cycle */

// Call it once in your app. At the root of your app is the best place
toast.configure();

function Paylink(props) {
  const { match } = props;
  return (
    <div className="paylink-screens">
      <Suspense fallback={<RenderLoader />}>
        <LayoutPaylink>
          <Switch>
            <ApurataRoute path={`${match.url}/`} exact component={Landing} />
            <ApurataRoute path={`${match.url}/dashboard/:paylinkId`} exact component={Dashboard} auth />
            <ApurataRoute path={`${match.url}/dashboard`} exact component={DashboardList} auth />
            <ApurataRoute path={`${match.url}/profile/:paylink_id`} exact component={ProfileForm} auth />
          </Switch>
        </LayoutPaylink>
      </Suspense>
    </div>
  );
}

Paylink.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

function PosOrder(props) {
  const { match } = props;
  return (
    <div className="paylink-screens">
      <Suspense fallback={<RenderLoader />}>
        <LayoutPaylink>
          <Switch>
            <ApurataRoute path={`${match.url}/dashboard/:posClientId`} exact component={Dashboard} auth />
          </Switch>
        </LayoutPaylink>
      </Suspense>
    </div>
  );
}
PosOrder.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

function RedirectCotizadorAcuotaz() {
  const params = useParams();

  const plkPattern = /(plk|paylink)/i;
  if (plkPattern.test(params.client_id)) {
    return <Redirect to={`/cotizador-acuotaz/plk/${params.client_id}`} />;
  }
  return <Redirect to={`/cotizador-acuotaz/pos/${params.client_id}`} />;
}

function RedirectTotiendas() {
  return <Redirect to="/tiendas" />;
}

function runOnce(fn, context) {
  // https://stackoverflow.com/a/12713611
  let result;
  let fnCopy = fn;
  return (...rest) => {
    if (fnCopy) {
      result = fnCopy.apply(context || this, rest);
      fnCopy = null;
    }
    return result;
  };
}

const setUnhandledRejectionEventListenerOnce = runOnce((listener) => window.addEventListener('unhandledrejection', listener));

function App() {
  const [error, setError] = useState(null);
  const [gb, setGb] = useState(null);
  const [loading, setLoading] = useState(true);

  // Code in this scope can run more than once
  window.acuotazAppSetError = setError;

  useEffect(() => {
    // This useEffect() should alwas call setGb() before finishing
    async function setSessionCreateGbClientAndSaveUtms() {
      setLoading(true);
      let sessionId = null;
      // Try 3 times in order to prevent connection errors
      try {
        sessionId = await SaveSessionIdLocalStorage();
      } catch (err1) {
        try {
          sessionId = await SaveSessionIdLocalStorage();
        } catch (err2) {
          try {
            sessionId = await SaveSessionIdLocalStorage();
          } catch (err3) {
            const err = new Error('Fallo al inicializar sesión anónima, intente recargar la página');
            err.diagnosis_info = [err3, err2, err1].map((e) => e.message).join(' - ');
            Sentry.captureException(err, {
              extra: {
                url: window.location.href,
                userAgent: navigator.userAgent,
                sessionId,
                errors: [err3, err2, err1],
              },
            });
            setError(err);
            setLoading(false);
            return;
          }
        }
      }
      const newGb = getOrCreateGrowthbookClient(sessionId);
      // Load features from the GrowthBook API and initialize the SDK
      newGb.loadFeatures();
      setGb(newGb);
      setLoading(false);
      const utmsParams = Object.fromEntries(new URLSearchParams(window.location.search));
      // Same as in the backend, we only save the utms if a utm_source exists
      const hasUtmSource = Object.keys(utmsParams).includes('utm_source');
      if (hasUtmSource) {
        SaveUtmsInSession(utmsParams);
      }
    }
    setSessionCreateGbClientAndSaveUtms();
  }, []);

  setUnhandledRejectionEventListenerOnce((event) => {
    /*
      El propósito de esta función es bloquear toda la interfaz con un error si es que hay un error sin manejar en una promesa.
      La idea es hacer explícitas las promesas en las que no se maneja el error, lo más común son los fetch.
      En el caso de un fetch de google ads bloqueado por el ad-blocker, el evento no tiene suficiente información para poderlo diferenciar de un fetch a apurata.com,
      por esto es que lo estamos deshabilitando en producción, para no bloquear el funnel a los clientes que tienen ad-blocker.
    */
    if (import.meta.env.REACT_APP_API_SERVER.includes('localhost')) {
      const eventReason = event.reason;
      eventReason.message = `Localhost only: ${eventReason.message}`;
      window.acuotazAppSetError(eventReason); // Cannot use setError directly because it changes on every run of App()
    } else {
      console.warn('UNHANDLED PROMISE REJECTION', event);
    }
  });

  if (loading) {
    return <LoadingSpinner show text="Cargando ..." />;
  }
  if (error) {
    return <ErrorComponent error={error} />;
  }

  return (
    <GrowthBookProvider growthbook={gb}>
      <BrowserRouter basename="/app">
        <CompatRouter>
          <div className="App">
            <Suspense fallback={<RenderLoader />}>
              <GlobalContextProviders error={error} setError={setError}>
                <Sentry.ErrorBoundary fallback={ErrorFallback}>
                  <AppLayout>
                    <Switch>
                      <ApurataRoute exact path="/" component={LandingMerchant} />
                      <ApurataRoute exact path="/login" component={Login} checkUrl />
                      <ApurataRoute exact path="/linea-extendida/:order_id" component={ScreenExtendedLine} />
                      <ApurataRoute exact path="/validar-identidad" component={CameraValidation} checkUrl />
                      <ApurataRoute exact path="/apurata/validar-identidad" component={CameraValidation} checkUrl />
                      <ApurataRoute
                        exact
                        path="/verificar-email-fono-id"
                        component={PhoneEmailIdVerification}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/apurata/verificar-email-fono-id"
                        component={PhoneEmailIdVerification}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/validando-informacion"
                        component={ValidatingPhotoAndPayment}
                        checkUrl
                        setIntervalTime={10000}
                      />
                      <ApurataRoute
                        exact
                        path="/validando-pago"
                        component={ValidatingTransaction}
                        checkUrl
                        setIntervalTime={10000}
                      />
                      <ApurataRoute exact path="/redirigir-a-tienda" component={RedirectCountdown} checkUrl />
                      <ApurataRoute exact path="/test-prd" component={ScreenTestPrd} />
                      <ApurataRoute exact path="/completar-pago" component={ScreenDownPayment} checkUrl />
                      <ApurataRoute exact path="/completar-pago-con-tarjeta" component={DownpaymentCard} checkUrl />
                      <ApurataRoute exact path="/completar-pago-con-yape" component={DownpaymentYape} />
                      <ApurataRoute exact path="/completar-pago-por-transferencia" component={DownpaymentTransfer} checkUrl />
                      <ApurataRoute exact path="/cotizador-acuotaz/:client_id" component={RedirectCotizadorAcuotaz} />
                      <ApurataRoute exact path="/cotizador-acuotaz/plk/:client_id" component={ScreenSimulatorAcuotaz} />
                      <ApurataRoute exact path="/cotizador-acuotaz/pos/:client_id" component={ScreenSimulatorAcuotaz} />
                      {/* Used to display the Monthly simulator to client_ids that have 2free installments */}
                      <ApurataRoute exact path="/precal/:client_id" component={ScreenSimulatorPrecal} />
                      <ApurataRoute exact path="/precal-eval" component={PrecalEval} />
                      <ApurataRoute exact path="/precal-calificado" component={PrecalApproved} />
                      <ApurataRoute exact path="/precal-denegado" component={PrecalRejected} />
                      <ApurataRoute exact path="/precal-incompleto" component={PrecalIncompleted} />
                      <ApurataRoute
                        exact
                        path="/aplicar/paso-uno"
                        component={StepOne}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/apurata/aplicar/paso-uno"
                        component={StepOne}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/web/conflicto/dni-distinto"
                        component={DistinctDni}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/pos/conflicto/dni-distinto"
                        component={DistinctDni}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/aplicar/denegado"
                        component={ApplyDenied}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/pos/aplicar/denegado"
                        component={ApplyDenied}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/deposito-listo"
                        component={Funded}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/mas-data"
                        component={MoreData}
                      />
                      <ApurataRoute
                        exact
                        path="/conflicto/denegado"
                        component={ApplyDeniedOtherAccount}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/conflicto/esperando-luego-de-pagar"
                        component={ApplyReapplyWait}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/pos/reintentar-informacion"
                        component={RetryValidation}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/apurata/reintentar-informacion"
                        component={RetryValidation}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/por-que-no"
                        component={WhyDeclined}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/pos/por-que-no"
                        component={WhyDeclined}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/validando"
                        component={ValidatingApurata}
                        checkUrl
                        redirectOnNonActiveLoan
                        setIntervalTime={20000}
                      />
                      <ApurataRoute
                        exact
                        path="/aplicar/external-problems"
                        component={CancelExternalProblems}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute exact path="/not-authorized-create-loans" component={DontCreateLoans} />
                      <ApurataRoute exact path="/we-cant-finance-you" component={DontFinanceLoan} />
                      <ApurataRoute exact path="/fail-cancel-lend" component={FailCancelLend} />
                      <ApurataRoute exact path="/acuotaz/:name" component={NudgeAcuotaz} />
                      <ApurataRoute
                        exact
                        path="/estado-aplicacion"
                        component={ApplicationStatus}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/apurata/estado-aplicacion"
                        component={ApplicationStatus}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/apurata/estado-aplicacion-personal"
                        component={LowerApprovedAmount}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute exact path="/integraciones" component={Integrations} />
                      <ApurataRoute exact path="/cuotas_apurata_vs_mercado_pago" component={MercadoPago} />
                      <ApurataRoute exact path="/plugin/:name" component={Plugin} />
                      <ApurataRoute exact path="/para-clientes" component={HomePeople} />
                      <ApurataRoute exact path="/payment-instructions/:order_id" component={PaymentInstructions} />
                      <ApurataRoute
                        exact
                        path="/funding-code-pos"
                        component={FundingCodePos}
                        auth
                        checkUrl
                        setIntervalTime={10000}
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/compra-exitosa"
                        component={SuccessPurchase}
                        auth
                        checkUrl
                        redirectOnNonActiveLoan
                      />

                      {/* flow after waiting-payment conflict to pay full lend */}
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/pagar-credito-actual-total"
                        component={DownpaymentFullLoan}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/registrar-voucher"
                        component={RegisterVoucher}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/registrar-pago"
                        component={PaymentFunnel}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/registrar-pago-corregido"
                        component={PaymentFunnel}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/registrar-voucher-corregido"
                        component={RegisterVoucher}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/test-voucher-ocr-js"
                        component={VouchersTableTest}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/validando-pago"
                        component={ResultsValidationDownpayment}
                        checkUrl
                        setIntervalTime={10000}
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/pago-validado-exitosamente"
                        component={ResultsValidationDownpayment}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/pago-invalidado"
                        component={ResultsValidationDownpayment}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/pago-monto-menor-al-monto-total"
                        component={ResultsValidationDownpayment}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/full-pmt-flow/validacion-pago-tardada"
                        component={ResultsValidationDownpayment}
                        checkUrl
                      />
                      <ApurataRoute
                        exact
                        path="/fotocheck/:personKey"
                        component={PhotocheckScreen}
                      />
                      <ApurataRoute
                        exact
                        path="/evaluando"
                        component={Evaluating}
                        checkUrl
                        redirectOnNonActiveLoan
                      />
                      <ApurataRoute
                        exact
                        path="/mi-perfil"
                        component={MyProfile}
                        redirectOnNonActiveLoan
                      />

                      <ApurataRoute exact path="/register-payment" component={RegisterDownpaymentVoucher} checkUrl />
                      <ApurataRoute exact path="/partners/:name" component={LandingPartner} />
                      {/* paylink route should be just Route because renders a Switch of ApurataRoutes,
                          avoiding to call ApurataRoute hooks twice */}
                      <Route path="/paylink" component={Paylink} />
                      <Route path="/pos" component={PosOrder} />
                      {/* Steps 2 to 5 More Data Funnel Forms */}
                      <Route path="/info/base-banked" component={MoreDataForms} />
                      <Route path="/info/base-nonbanked" component={MoreDataForms} />
                      <ApurataRoute exact path="/registrar-pago/registrar-voucher" component={RegisterVoucher} />
                      <ApurataRoute exact path="/registrar-pago/nuevo-pago" component={PaymentFunnel} />
                      <ApurataRoute exact path="/tiendas-comprar-en-cuotas" component={RedirectTotiendas} />
                      <ApurataRoute exact path="/tiendas" component={LandingStores} />
                      <ApurataRoute
                        exact
                        path="/instrucciones-para-pagar-la-inicial-externamente"
                        component={ExternalDownPaymentInstructions}
                        checkUrl
                        setIntervalTime={10000}
                        redirectOnNonActiveLoan
                      />
                      <CognitoLayout>
                        <ApurataRoute exact path="/cognito/sign-in" component={CognitoLogin} />
                        <ApurataRoute exact path="/cognito/sign-out" component={CognitoLogin} />
                        <ApurataRoute exact path="/cognito/verify-email" component={CognitoVerifyEmail} />
                        <ApurataRoute exact path="/cognito/reset-password" component={CognitoResetPassword} />
                      </CognitoLayout>
                      <ApurataRoute component={NotFound} />
                    </Switch>
                  </AppLayout>
                </Sentry.ErrorBoundary>
              </GlobalContextProviders>
            </Suspense>
          </div>
        </CompatRouter>
      </BrowserRouter>
    </GrowthBookProvider>
  );
}

export default App;
